.testimonials .thumbnail {
  background-color: white;
  color: $charcoal;

  .thumbnail-header {
    position: relative;
    cursor: pointer;
    overflow: hidden;
    background-color: $blue-dark;
    height: 298px;

    &:after {
      content: '\A';
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      background: rgba(86, 119, 183, 1);
      opacity: .2;
    }

    &:hover img {
      @include scale-element(2);
    }

    img {
      @include scale-element(1);
      width: 100%;
    }

    .material-icons {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-size: 6em;
      color: white;
      z-index: 1;
    }

    .caption {
      position: absolute;
      bottom: 0;
      z-index: 2;
    }
  }

  .content {
    padding: $thumbnail-content-padding;
    background: transparent url("/images/quote@2x.png") no-repeat 24px 26px;
    background-size: 16%;
    height: 130px;
  }
}
