.pseudo-bullets {
  .list-item {
    display: block;
    white-space: nowrap;
    margin-bottom: 1em;

    .bullet,
    .text {
      display: inline-block;
      vertical-align: top;
    }

    .bullet {
      margin-right: .5em;
      font-weight: bold;
    }

    .text {
      white-space: normal;
    }
  }
}
