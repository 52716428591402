.modal {
  .modal-content {
    @include material-shadow(1);
  }

  .modal-header,
  .modal-body,
  .modal-footer {
    padding: 0;
  }

  .modal-body {
    text-align: center;
  }

  .modal-footer {
    padding: 1em;
    text-align: left;
    border: none;

    .btn {
      margin-bottom: 0;
    }
  }

  .close {
    @include material-shadow(1);
    background: white;
    color: $sand;
    border-radius: 50%;
    padding: .4em;
    position: absolute;
    right: -1em;
    z-index: 100;
    opacity: 1;
    top: -1em;
  }
}
