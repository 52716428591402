$icon-font-path: '../fonts/';

@import "utilities/_pre_bootstrap_customizations";

$grid-float-breakpoint: 1020px;

// bower:scss
@import "bower_components/bootstrap-sass/assets/stylesheets/_bootstrap.scss";
// endbower

@import "utilities/bootstrap_em";
@import "utilities/text";
@import "utilities/mixins";

@import "atoms/button";
@import "atoms/headings";
@import "atoms/links";
@import "atoms/material_icons";
@import "atoms/nav_divider";

@import "molecules/card";
@import "molecules/card_pricing";
@import "molecules/thumbnail";
@import "molecules/testimonial_thumbnail";
@import "molecules/brand_logo_thumbnail";
@import "molecules/carousel";
@import "molecules/icon_gallery";
@import "molecules/pseudo_bullets";
@import "molecules/modal";
@import "molecules/signup_form";
@import "molecules/video_wrapper";

@import "organisms/navbar";
@import "organisms/section";
@import "organisms/hero";
@import "organisms/hero_video";
@import "organisms/footer";

body {
  background-color: white;
  font-size: $font-size-small;
  padding-top: 70px;
}
