.signup-form-container {
  min-height: 140px;

  .btn {
    margin-bottom: 0;
  }

  .form-feedback {
    left: 0;
    position: absolute;
    right: 0;
  }
}
