.hero {
  background: $sand url('/images/hero_video_fallback.jpg') center center no-repeat;
  background-size: cover;
  padding: 4em 0;
  position: relative;
  margin-bottom: 2em;

  .headline {
    color: #333;
    margin-bottom: 0;
  }

  .sub-headline {
    margin-bottom: 1em;
  }

  .more {
    bottom: .5em;
    left: 50%;
    position: absolute;
    text-transform: uppercase;
    transform: translateX(-50%) translateY(0);
    z-index: 2;
  }

  .splash-start-here {
    position: absolute;
    top: 40%;
    right: 5%;
  }

  @media (min-width: $screen-md) {
    .more {
      bottom: -1em;

      .more-text {
        margin-bottom: 1.5em;
      }

      .more-link {
        background-color: white;
        box-shadow: 0 2px 10px 0 rgba(0, 0, 0, .2);
        border-radius: 50%;
        padding: 1em .8em;

        &:after {
          box-shadow: 0 2px 4px 0 rgba(0, 0, 0, .1);
        }
      }
    }

    .sub-headline {
      margin-bottom: 3em;
    }
  }

  @media (min-width: $screen-lg) {
    .splash {
      right: 10%;
    }
  }
}
