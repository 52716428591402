.pricing-overview .card {
  margin-bottom: 1em;

  .headline {
    margin-bottom: 0;
  }

  .card-content {
    min-height: 160px;
    padding: 2em;
  }

  .subscription-type {
    font-size: $font-size-xsmall;
    text-transform: capitalize;
    font-weight: normal;
  }
}
