.nav .nav-divider {
  @include nav-divider($gray-light)
  width: 2px;
  height: 2em;
  margin: 1.65em 1em;

  @media (max-width: $grid-float-breakpoint) {
    display: none;
  }
}
