$purple:                  #2C4165;
$orange:                  #fcb034;
$orange-dark:             darken($orange, 10%);
$orange-light:            lighten($orange, 10%);
$sand:                    #B4B8AB;
$sand-light:              #efefef;
$blue:                    #0068ac;
$blue-dark:               #005d9b;
$gray-light:              #B4B8AB;
$orange:                  #fcb034;
$turquoise:               #5BC0DE;
$red:                     #D9534F;
$gray:                    #efefef;
$charcoal:                #6D7579;

$bg-gray:                 #efefef;
$bg-gray-light:           lighten($bg-gray, 3%); // #f7f7f7

$btn-primary-text:        $blue-dark;
