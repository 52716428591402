.icon-gallery {
  .item {
    text-align: center;

    > i {
      margin-bottom: .5em;
    }

    h4 {
      margin-bottom: 1em;
    }

    @media screen and (min-width: $screen-md) {
      margin-bottom: 4em;
      padding: 0 3em;
    }
  }
}
