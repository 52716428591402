.did-you-know .thumbnail {
  background-color: transparent;
  text-align: center;
  border: none;

  .circle {
    position: relative;

    canvas {
      max-width: 100px;
      max-height: 100px;
    }

    .material-icons {
      color: $sand;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translateX(-50%) translateY(-50%);
    }
  }

  .caption {
    color: $gray;
  }
}
