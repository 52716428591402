.section {
  padding: 2em 0;

  .headline,
  .sub-headline {
    text-align: center;
  }

  .headline {
    margin-bottom: 1em;
  }

  &.dark-bg {
    background-color: $blue;
    color: white;
  }

  &.light-bg {
    background-color: $sand-light;
  }

  &.dotted-bg {
   margin-bottom: 2em;
   padding: 1.4em 4em;
  }

  &.section-trial {
    .headline {
      text-align: left;
    }

    .splash-no-obligation {
      margin-top: 1em;
    }
  }
}
