html {
  font-size: $font-size-px;
}

// Change styling for elements that mix pixels and ems,
// and override styles wrongly set in px

pre {
  font-size: ($font-size-base - (1 / $font-size-root));
}

.form-control-feedback {
  width: $input-height-base + (2 / $font-size-root) + $font-unit;
  height: $input-height-base + (2 / $font-size-root) + $font-unit;
}
.input-lg + .form-control-feedback {
  width: $input-height-large + (2 / $font-size-root) + $font-unit;
  height: $input-height-large + (2 / $font-size-root) + $font-unit;
}
.input-sm + .form-control-feedback {
  width: $input-height-small + (2 / $font-size-root) + $font-unit;
  height: $input-height-small + (2 / $font-size-root) + $font-unit;
}

.form-control {
  height: auto;
  line-height: $line-height-base;
  padding: $padding-base-vertical $padding-base-horizontal;
}

.form-horizontal {
  .radio,
  .checkbox,
  .radio-inline,
  .checkbox-inline {
    padding: $padding-base-vertical;
    border: 1px solid transparent;
  }
  .radio,
  .checkbox {
    min-height: calc(($line-height-computed + $padding-base-vertical) + 1px);
  }
  .form-control-static {
    padding-top: $padding-base-vertical;
    padding-bottom: $padding-base-vertical;
    border-top: 1px solid transparent;
    border-bottom: 1px solid transparent;
  }
  @media (min-width: $screen-sm-min) {
    .control-label {
      padding-top: $padding-base-vertical;
      border-top: 1px solid transparent;
    }
  }
}
