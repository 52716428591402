.card {
  background-color: $sand-light;

  &.card-default {
    background-color: white;

    .card-content {
      color: $gray-light;
    }
  }

  &.card-primary {
    @include material-shadow(1);
    background-color: $blue;
    color: white;

    .card-header {
      background-color: rgba(black, .1);
    }
  }

  &.card-gray {
    background-color: $sand-light;

    .card-header {
      background-color: rgba(black, .1);;
    }
  }

  > div {
    padding: 1em;
  }

  .card-header {
    text-align: center;

    .card-title {
      margin: 0;
    }
  }

  .card-footer {
    text-align: center;
  }
}
