.carousel {
  background-size: 80%;
  margin-bottom: 1em;

  .carousel-control {
    background-image: none;
    background-color: rgba(0, 0, 0, .2);
    border-radius: 4px;
    height: 48px;
    width: 48px;
    vertical-align: middle;
    opacity: 1;
    top: 40%;
    z-index: 2;
    margin: 20px;

    > i {
      transform: translateX(-50%) translateY(50%);
      position: absolute;
      text-shadow: 0 0 0;
    }
  }

  .carousel-inner {
    margin-bottom: 2em;

    > .item {
      .desktop-composite {
        width: 500px;
        margin: 0 auto;
      }

      .desktop-screenshot {
        position: absolute;
        top: 1.5%;
        left: 50%;
        transform: translate(-50%, 0);
        width: 490px;
        max-width: 98%;
      }
    }
  }

  .carousel-card-indicators {
    margin-bottom: 2em;
    position: relative;
    width: 100%;
    margin: 0 0 3em;
    left: 0;
    top: 0;

    .card {
      background-color: transparent;
      cursor: pointer;
      padding: 1em;
      text-align: center;
      display: inline-block;
      width: 24%;
      height: auto;
      vertical-align: top;

      &:hover {
        background-color: $sand-light;

        hr {
          border-color: black;
        }
      }

      &.active {
        @include material-shadow();
        background-color: $blue;
        color: white;
        transition: 400ms;

        .card-content {
          color: white;

          hr {
            border-color: $orange;
          }
        }
      }

      hr {
        border-top: 2px solid $sand-light;
        margin-top: 0;
        width: 60px;
      }
    }
  }

  @media screen and (min-width: $screen-sm) {
    .carousel-control {
      top: 50%;
    }
  }

  @media screen and (max-width: $screen-sm) {
    background-position: center center;

    .carousel-card-indicators .card {
      display: none;

      &.active {
        display: block;
        width: 100%;
      }
    }
  }
}
