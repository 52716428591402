.footer {
  a {
    color: white;

    &:hover,
    &:focus {
      color: $orange;
      text-decoration: none;
    }
  }

  li i {
    color: $orange;
  }

  .list-group-item {
    margin-bottom: 0;
    background-color: transparent;
    border: none;
    color: white;
    padding: .2em;
  }

  .text-contact,
  .text-copyright {
    text-align: center;
  }

  .text-copyright {
    margin-top: 2em;
  }

  .divider {
    margin: 0 1em;
  }

  @media screen and (min-width: 768px) {
    .text-contact {
      text-align: right;
    }
  }
}
