.text-white {
  color: rgba(255, 255, 255, .5);
}

.text-black {
  color: rgba(0, 0, 0, .5);
}

.text-gray {
  color: $gray-light;
}

.text-gray-dark {
  color: $gray-dark;
}

.text-charcoal {
  color: $charcoal;
}

.text-green {
  color: $orange-light;
}

.text-green-dark {
  color: $orange-dark !important;
}

.text-ellipsis {
  display: block;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  box-sizing: border-box;
}
