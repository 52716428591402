.navbar {
  text-transform: uppercase;
  border: none;
  font-weight: bold;
  box-shadow: 0 1px rgba(0, 0, 0, .1);

  .navbar-brand {
    padding-top: 1.25em;
    padding-bottom: 1.25em;
  }

  .navbar-nav > li > a {
    border-bottom: 4px solid transparent;
    padding-top: 2em;
    padding-bottom: 1.7em;

    &:hover,
    &:focus,
    &.active {
      border-bottom-color: $orange-dark;
    }

    & > img {
      max-height: 1.35em;
      display: block;
    }
  }

  .navbar-toggle {
    padding-top: 1.5em;
    padding-bottom: 1.5em;
  }

  .navbar-collapse {
    padding-top: 0;
    padding-bottom: 0;

    @media (max-width: $screen-xs) {
      background-color: $blue-dark;
      padding-top: $navbar-padding-vertical;
      padding-bottom: $navbar-padding-vertical;

      li > a {
        color: white;
      }

      li > a:hover,
      li > a:focus {
        color: $gray-light;
      }
    }
  }
}
