@import 'colors';
//
// Variables
// --------------------------------------------------


//== Colors Gray and brand colors for use across Bootstrap.

$gray-base:              #000 !default;
$gray-darker:            lighten($gray-base, 13.5%) !default; // #222
$gray-dark:              lighten($gray-base, 20%) !default;   // #333
$gray:                   lighten($gray-base, 33.5%) !default; // #555
$gray-light:             lighten($gray-base, 46.7%) !default; // #777
$gray-lighter:           lighten($gray-base, 93.5%) !default; // #eee

$brand-primary:         $blue-dark !default;
$brand-success:         $orange !default;
$brand-info:            $turquoise !default;
$brand-warning:         $orange !default;
$brand-danger:          $red !default;

//== Scaffolding Settings for some of the most global styles.

//** Background color for `<body>`.
$body-bg:               $bg-gray;
//** Global textual link color.
$link-color:            $brand-primary;

//== Typography Font, line-height, and color for body text, headings, and more.
$font-family-sans-serif:  'Source Sans Pro', 'Helvetica Neue', Helvetica, Arial, sans-serif;
$font-family-serif:       'DejaVu Serif', Georgia, 'Times New Roman', Times, serif;

//** Unit-less `line-height` for use in components like buttons.
$line-height-base:        1.375; // 22/16
$line-height-large:       1.25;

//== Components Define common padding and border radius sizes and more.

$border-radius-base:        3px !default;
$border-radius-large:       $border-radius-base;
$border-radius-small:       $border-radius-base;

//** Global background color for active items (e.g., navs or dropdowns).
$component-active-bg:       $blue-dark;

//== Tables Customizes the `.table` component with basic values, each used across all table variations.

//** Background color used for `.table-striped`.
$table-bg-accent:               $bg-gray-light;
//** Background color used for `.table-hover`.
$table-bg-hover:                $bg-gray;

//** Border color for table and cell borders.
$table-border-color:            $gray-lighter;


//== Buttons For each of Bootstrap's buttons, define text, background and border color.

$btn-border-radius-base:         $border-radius-small;
$btn-font-weight:                700;

$btn-primary-bg:                 $orange;
$btn-primary-color:              black;

$btn-default-bg:               $blue-dark;
$btn-default-color:            white;
$btn-default-border:           rgba(#000, .1);

$btn-warning-border:             rgba(#000, .1);

$btn-danger-border:              rgba(#000, .1);

//== Forms
$input-color:                    $gray-dark;

//** Border color for inputs on focus
$input-border-focus:             $blue;

//== Pagination

$pagination-active-bg:          $blue;
$pagination-active-border:      $blue;

//== Form states and alerts Define colors for form feedback states and, by default, alerts.

// We should change these according to the new design in the main application,
// but I think we should run it by Design, since the new look of alerts etc. is
// a bit heavy right now. (jn@autobutler.dk)

$state-success-text:             #3c763d !default;
$state-success-bg:               #dff0d8 !default;
$state-success-border:           darken(adjust-hue($state-success-bg, -10), 5%) !default;

$state-info-text:                #31708f !default;
$state-info-bg:                  #d9edf7 !default;
$state-info-border:              darken(adjust-hue($state-info-bg, -10), 7%) !default;

$state-warning-text:             #8a6d3b !default;
$state-warning-bg:               #fcf8e3 !default;
$state-warning-border:           darken(adjust-hue($state-warning-bg, -10), 5%) !default;

$state-danger-text:              #a94442 !default;
$state-danger-bg:                #f2dede !default;
$state-danger-border:            darken(adjust-hue($state-danger-bg, -10), 5%) !default;

//== Alerts Define alert colors, border radius, and padding.

$alert-border-radius:         $border-radius-small;

$alert-success-bg:            $state-success-bg !default;
$alert-success-text:          $state-success-text !default;
$alert-success-border:        $state-success-border !default;

$alert-info-bg:               $state-info-bg !default;
$alert-info-text:             $state-info-text !default;
$alert-info-border:           $state-info-border !default;

$alert-warning-bg:            $state-warning-bg !default;
$alert-warning-text:          $state-warning-text !default;
$alert-warning-border:        $state-warning-border !default;

$alert-danger-bg:             $state-danger-bg !default;
$alert-danger-text:           $state-danger-text !default;
$alert-danger-border:         $state-danger-border !default;

//== Panels

$panel-border-radius:         $border-radius-small;

//== Wells

$well-bg:                     $bg-gray;

//== Close

$close-font-weight:           200;
$close-color:                 $gray-darker;
$close-text-shadow:           none;

//== Type

//** Horizontal line color.
$hr-border:                   #ddd;

//
// Use em or rem font-size in Bootstrap 3
// --------------------------------------------------

$font-size-root: 16;
$font-size-px: 16px;
$font-unit: 0em; // Pick em or rem here


// Convert all variables to em

$font-size-base:  1 + $font-unit;
$font-size-large: 1.25 + $font-unit;
$font-size-small: .875 + $font-unit;
$font-size-xsmall: .75 + $font-unit;

$font-size-h1:    2.5 + $font-unit;
$font-size-h2:    2.125 + $font-unit;
$font-size-h3:    1.625 + $font-unit;
$font-size-h4:    1.25 + $font-unit;
$font-size-h5:    $font-size-base;
$font-size-h6:    .875 + $font-unit;

$line-height-computed:    $line-height-base + $font-unit;

$padding-base-vertical:     .625 + $font-unit;
$padding-base-horizontal:   $font-size-base;

$padding-large-vertical:    $padding-base-vertical;
$padding-large-horizontal:  $padding-base-horizontal;

$padding-small-vertical:    $padding-base-vertical;
$padding-small-horizontal:  $padding-base-horizontal;

$padding-xs-vertical:       .25 + $font-unit;
$padding-xs-horizontal:     .75 + $font-unit;

//** Padding for `<th>`s and `<td>`s.
$table-cell-padding:            .5 + $font-unit;
//** Padding for cells in `.table-condensed`.
$table-condensed-cell-padding:  .25 + $font-unit;

//** Default `.form-control` height
$input-height-base:             ($padding-base-vertical * 2) + $line-height-base + $font-unit;
//** Large `.form-control` height
$input-height-large:            $input-height-base;
//** Small `.form-control` height
$input-height-small:            $input-height-base;

//** `.form-group` margin
$form-group-margin-bottom:      $font-size-base;

//== Navs

// Basics of a navbar
$navbar-height:                 50;
$navbar-margin-bottom:          0;
$navbar-padding-vertical:       (($navbar-height / $font-size-root) - $line-height-base) / 2 + $font-unit;
$navbar-border-radius:          0;

$navbar-default-bg:             white;
$navbar-default-border:         transparent;
$navbar-default-link-color:     $blue-dark;
$navbar-default-link-hover:     $blue-dark;
$navbar-default-toggle-icon-bar-bg:      $orange;
$navbar-default-toggle-hover-bg:    transparent;
$navbar-default-toggle-border-color: transparent;

//=== Shared nav styles
$nav-link-padding:              .625 + $font-unit 1 + $font-unit;

//== Pager

$pager-border-radius:           1 + $font-unit;

//== Jumbotron

$jumbotron-padding:             2 + $font-unit;

//== Alerts

$alert-padding:                 1 + $font-unit;

//== Panels

$panel-body-padding:          1 + $font-unit;
$panel-heading-padding:       .625 + $font-unit 1 + $font-unit;

//== Badges

$badge-border-radius:         .625 + $font-unit;

//== Breadcrumbs

$breadcrumb-padding-vertical:   .5 + $font-unit;
$breadcrumb-padding-horizontal: 1 + $font-unit;

//== Type

//** Blockquote font size
$blockquote-font-size:        1.25 + $font-unit;

//== Thumbnails

$thumbnail-padding:           0 !default;
$thumbnail-caption-color:     white !default;
$thumbnail-caption-padding:   1em 1.5em !default;
$thumbnail-content-padding:   3em 1.5em !default;
