.hero.hero-video {
  background: none;
  min-height: 550px;

  .video-container {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    z-index: -100;
    overflow: hidden;

    video {
      background: url('/images/hero_video_fallback.jpg') center center no-repeat;
      background-size: cover;
      min-width: 100%;
      min-height: 100%;
    }
  }

  @media screen and (min-width: $screen-sm) {
    height: 80vh;
  }
}
