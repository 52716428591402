@mixin material-shadow($level: 1) {
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.12),
              0 20px 40px -10px rgba(44, 64, 102, .30),
              0 20px 50px -10px rgba(0, 0, 0, .5);
}

@mixin scale-element($level: 1) {
  transition-duration: 500ms;
  transition-timing-function: ease-out;

  @if $level == 1 {
    transform: scale(1, 1);
  } @else if $level == 2 {
    transform: scale(1.2, 1.2);
  }
}
